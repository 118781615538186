import apple from "./../static/smallApple.svg";

export const SmallAppleButton = () => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href="https://paced.onelink.me/OHpM/extension"
    >
      <img src={apple} alt="apple store icon" />
    </a>
  );
};
