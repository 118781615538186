import { ReactComponent as Google } from "./../../static/google.svg";
import { ReactComponent as Frame1 } from "./../../static/frame1.svg";
import energy from "./../../static/energy.svg";
import schedule from "./../../static/schedule.svg";
import focus from "./../../static/focus.svg";

import { OnboardingPage } from "../../components/OnboardingContainer";
import { useGoogleAuthSubscriber } from "../../utils/auth";
import { useState } from "react";
import { useLocation } from "wouter";
import Button from "../../components/Button";

const ITEMS_DESCRIPTORS = [
  {
    icon: energy,
    title: "Sync work with your energy patterns",
  },
  {
    icon: schedule,
    title: "Optimize your packed schedule",
  },
  {
    icon: focus,
    title: "Learn when you focus best",
  },
] as const;

export const WelcomePage = () => {
  const [, setLocation] = useLocation();
  const [loadingStatus, setLoadingStatus] = useState(false);
  const { status, signIn } = useGoogleAuthSubscriber();
  if (status === "member") {
    setLocation("/circadian");
  }

  if (status === "guest") {
    setLocation("/nonregistered");
  }

  const onClickSignIn = () => {
    setLoadingStatus(true);
    signIn();
  };

  return (
    <OnboardingPage>
      <OnboardingPage.Content>
        <OnboardingPage.Content.Column position="left">
          <div className="w-[408px] flex-col mr-auto items-center justify-center">
            <h1 className="text-l font-bold text-black mb-4 w-full">
              Welcome to Paced!
            </h1>
            <h2 className="text-base text-gray-80 mb-6 w-full">
              Here's how Paced can help you get more from your workday:
            </h2>
            <div className="w-full flex flex-col space-y-4">
              {ITEMS_DESCRIPTORS.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center w-full bg-white rounded-xl border border-black/5 p-4 text-base"
                >
                  <img
                    src={item.icon}
                    className="w-8 h-8 mr-4"
                    alt={item.title}
                  />
                  {item.title}
                </div>
              ))}
            </div>
          </div>
        </OnboardingPage.Content.Column>
        <OnboardingPage.Content.Column position="right">
          <Frame1 className="ml-auto mr-auto" />
        </OnboardingPage.Content.Column>
      </OnboardingPage.Content>

      <Button
        className="mt-18 min-w-[335px]"
        leftIcon={<Google />}
        title="Sign in with Google"
        onClick={onClickSignIn}
        loading={loadingStatus}
      />
    </OnboardingPage>
  );
};
