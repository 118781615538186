import { useState } from "react";
import { NavBar } from "../../components";
import { useGoogleAuthSubscriber } from "../../utils/auth";

export const SignInPage = () => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const { signIn, status } = useGoogleAuthSubscriber();
  if (status === "member") {
    window.location.href = "/iframe/main";
  }

  if (status === "guest") {
    window.location.href = "/iframe/nonregistered";
  }

  return (
    <div className="h-screen bg-gray-10 ">
      <div className="overflow-hidden h-full">
        <div className="h-full flex justify-between items-center flex-col">
          <NavBar close />
          <div className="flex flex-1 justify-center items-center flex-col px-4">
            <h1 className="text-center text-lg font-bold text-black mb-3">
              Sign in to Paced
            </h1>
            <h2 className="text-center text-sm text-gray-80 mb-4">
              Start working smarter by connecting your work calendar
            </h2>

            <button
              disabled={loadingStatus}
              onClick={() => {
                setLoadingStatus(true);
                signIn();
              }}
              className="w-full h-11 px-6 py-3 bg-purple text-white rounded-full text-button font-semibold flex items-center justify-center"
            >
              {loadingStatus ? (
                <div className="border-2 border-white border-t-purple rounded-full animate-spin w-3 h-3"></div>
              ) : (
                "Connect Google Calendar"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
