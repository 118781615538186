import { OnboardingPage } from "../../components/OnboardingContainer";
import { useGoogleAuthSubscriber } from "../../utils/auth";
import { ReactComponent as Calendar } from "./../../static/calendar.svg";

export const LoadingPage = () => {
  const { status } = useGoogleAuthSubscriber();
  if (status === "member") {
    setTimeout(() => {
      window.location.href = "https://calendar.google.com/calendar/u/0/r";
    }, 1500);
  }

  return (
    <OnboardingPage bg={false}>
      <OnboardingPage.Content>
        <OnboardingPage.Content.Column position="full">
          <div className="flex justify-between flex-col items-center">
            <Calendar className="mb-6" />
            <h1 className="text-center text-l font-bold text-black mb-4">
              Setting up your calendar...
            </h1>
            <h2 className="text-center text-base text-sm text-gray-80 mb-6 ">
              We're transferring your energy predictions and getting your Paced
              calendar ready for desktop.
              <br />
              This will just take a moment.
            </h2>
          </div>
        </OnboardingPage.Content.Column>
      </OnboardingPage.Content>
    </OnboardingPage>
  );
};
