import { useRef, useState } from "react";
import { OnboardingPage } from "../../components/OnboardingContainer";

import { ReactComponent as ArrowRight } from "../../static/arrowright.svg";
import { ReactComponent as ChevronLeft } from "../../static/chevron-left.svg";
import frame1 from "../../static/frame1.svg";
import iconFrame1 from "../../static/frame-1-icon.svg";
import frame2 from "../../static/frame2.svg";
import iconFrame2 from "../../static/frame-2-icon.svg";
import Button from "../../components/Button";
import TimeInput from "../../components/TimeInput";

import { getAuth } from "firebase/auth";
import { useLocation } from "wouter";

const auth = getAuth();

export const OnboardingSlider = () => {
  const [, setLocation] = useLocation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isHiding, setIsHiding] = useState<number | null>(1);

  const [bedTime, setBedTime] = useState("22:00");
  const [wakeUpTime, setWakeUpTime] = useState("06:00");

  const timer = useRef<NodeJS.Timeout | null>(null);

  const changeSlide = (newSlide: number) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setIsHiding(currentSlide);
    timer.current = setTimeout(() => {
      setCurrentSlide(newSlide);
    }, 700);
  };

  const onContinue = async () => {
    if (currentSlide === 0) {
      changeSlide(1);
    } else {
      if (!auth.currentUser) {
        throw new Error("Empty user");
      }
      const accessToken = await auth.currentUser!.getIdToken();
      if (!accessToken) {
        throw new Error("Empty access token");
      }

      const body = JSON.stringify({
        sleepStart: bedTime.split(":").map(Number),
        sleepEnd: wakeUpTime.split(":").map(Number),
      });
      // create user in supabase
      await fetch("https://api.paced.com/v1/users/sleep-schedule", {
        method: "POST",
        headers: {
          "Access-Token": accessToken,
        },
        body,
      });

      setLocation("/loading");
    }
  };

  return (
    <OnboardingPage>
      <div className="p-4 w-full max-w-5xl mx-auto flex flex-col">
        <div className="w-full flex flex-col h-[390px] relative">
          <div
            className={`${currentSlide === 0 ? "show" : ""} ${
              isHiding === 0 ? "hide" : ""
            } slide flex absolute w-full h-full`}
          >
            <div className="slide-content w-[408px] mr-auto h-full">
              <img
                src={iconFrame1}
                className="icon w-20 h-20 mb-6"
                alt="Paced logo"
              />
              <h2 className="text-l font-bold text-black mb-4">
                Work smarter according to your Circadian zones
              </h2>
              <p className="text-gray-80 text-base">
                Energy and productivity fluctuate throughout the day in a
                process known as the Circadian rhythm. <br />
                <br />
                Paced divides your day into zones and makes personalized
                predictions about when focus is highest (and lowest) to increase
                performance and well-being at work.
              </p>
            </div>
            <img src={frame1} alt="Calendar zones" className="frame" />
          </div>

          <div
            className={`${currentSlide === 1 ? "show" : ""} ${
              isHiding === 1 ? "hide" : ""
            } slide flex absolute w-full h-full`}
          >
            <div className="slide-content w-[408px] mr-auto h-full">
              <img
                src={iconFrame2}
                className="icon w-20 h-20 mb-6"
                alt="Paced logo"
              />
              <h2 className="text-l font-bold text-black mb-4">
                When do you usually sleep?
              </h2>
              <p className="text-gray-80 text-base mb-6">
                Enter your usual sleep schedule so we can predict your energy
                patterns. Don’t worry if this varies a bit.
              </p>
              <div className="w-full flex flex-col">
                <TimeInput
                  onChange={setBedTime}
                  label="Bed time"
                  value={bedTime}
                  className="button-1"
                />
                <div className="h-3 flex-shrink-0"></div>
                <TimeInput
                  onChange={setWakeUpTime}
                  label="Wake up"
                  value={wakeUpTime}
                  className="button-2"
                />
              </div>
            </div>
            <img src={frame2} alt="Sleep" className="frame" />
          </div>
        </div>
        <div className="w-full flex mt-18 justify-end relative">
          <button
            onClick={() => changeSlide(0)}
            className={`${
              currentSlide === 0 ? "hide-to-left" : "show-from-right"
            } flex items-center justify-center absolute w-14 h-14 bg-white border border-gray-40 left-0 rounded-full`}
          >
            <ChevronLeft />
          </button>
          <Button
            title="Continue"
            onClick={onContinue}
            rightIcon={<ArrowRight />}
          />
        </div>
      </div>
    </OnboardingPage>
  );
};
