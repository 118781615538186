import { ReactComponent as AppleStore } from "./../../static/applestore.svg";
import { ReactComponent as Frame1 } from "./../../static/frame1.svg";
import { OnboardingPage } from "../../components/OnboardingContainer";
import { useGoogleAuthSubscriber } from "../../utils/auth";
import { useLocation } from "wouter";
import Button from "../../components/Button";

import { getAuth } from "firebase/auth";

const auth = getAuth();

export const NonRegisteredPage = () => {
  const { status, signInWithRedirect } = useGoogleAuthSubscriber();
  const [, setLocation] = useLocation();

  if (status === "member") {
    setLocation("/circadian");
  }

  const onConnect = async () => {
    if (!auth.currentUser) {
      throw new Error("Empty user");
    }
    const accessToken = await auth.currentUser!.getIdToken();
    if (!accessToken) {
      throw new Error("Empty access token");
    }
    // create user in supabase
    await fetch("https://api.paced.com/v1/users", {
      method: "POST",
      headers: {
        "Access-Token": accessToken,
      },
    });
    // getting refresh token for calendar
    signInWithRedirect();
  };

  return (
    <OnboardingPage>
      <OnboardingPage.Content>
        <OnboardingPage.Content.Column position="left">
          <h1 className="text-left text-l font-bold text-black mb-4">
            One more step
          </h1>
          <h2 className="text-left text-base text-gray-80 mb-6">
            Looks like you haven't connected your calendar yet. You can connect
            it by tapping on button below or by installing our app from the App
            Store.
          </h2>
          <div className="flex items-center">
            <Button title="Connect Calendar" onClick={onConnect} />
            <p className="text-sm mx-4 text-gray-80">OR</p>
            <a href="https://paced.onelink.me/OHpM/onboarding">
              <AppleStore />
            </a>
          </div>
        </OnboardingPage.Content.Column>
        <OnboardingPage.Content.Column position="right">
          <Frame1 className="mr-auto z-10" />
        </OnboardingPage.Content.Column>
      </OnboardingPage.Content>
    </OnboardingPage>
  );
};
