import React, { ReactNode } from "react";
import { NavBar } from "./NavBar";
import { ReactComponent as BG } from "./../static/bg.svg";

interface HasChildren {
  children?: React.ReactNode;
}
interface IOnboardingProps {
  bg?: boolean;
}

interface IOnboardingPageProps {
  Content: React.FC<HasChildren> & IContentProps;
  Button: React.FC<IButtonProps>;
}

interface IContentProps {
  Column: React.FC<IColumnProps>;
}

interface IColumnProps {
  children: ReactNode;
  position: "left" | "right" | "full";
}
interface IButtonProps {
  children: ReactNode;
  position?: "left" | "right" | "center";
}

const OnboardingPage: React.FC<HasChildren & IOnboardingProps> &
  IOnboardingPageProps = ({ children, bg = true }) => {
  return (
    <div className="h-screen bg-gray-10 overflow-hidden">
      <NavBar onboarding />

      {bg && (
        <BG className="absolute right-0 top-0 h-screen w-auto max-w-1/2" />
      )}
      <div className="flex h-[calc(100%_-_4rem)] justify-center items-center flex-col max-w-screen-lg  w-screen  m-auto">
        {children}
      </div>
    </div>
  );
};

const Content: React.FC<HasChildren> & IContentProps = ({ children }) => {
  return (
    <div className="flex justify-between items-center w-full">{children}</div>
  );
};

const Column: React.FC<IColumnProps> = ({ children, position }) => {
  const classes = {
    left: "w-[408px] flex-col mr-auto items-center justify-center",
    right: "z-10 items-end",
    full: "w-127 z-10 items-center mr-auto ml-auto",
  };
  return (
    <div className={classes[position as keyof typeof classes]}>{children}</div>
  );
};

const Button: React.FC<IButtonProps> = (
  { children, position } = { children: null, position: "center" }
) => {
  const classes = {
    left: "self-start",
    right: "self-end",
    center: "self-center",
  };
  return (
    <div className={`z-10 ${classes[position as keyof typeof classes]}`}>
      {children}
    </div>
  );
};

Content.Column = Column;
OnboardingPage.Content = Content;
OnboardingPage.Button = Button;

export { OnboardingPage };
