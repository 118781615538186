import { useLocation } from "wouter";
import { AppleButton, MenuItem, NavBar } from "../../components";
import { useGoogleAuthSubscriber } from "../../utils/auth";
import { ReactComponent as SignOut } from "./../../static/signout.svg";
import { ReactComponent as Sleep } from "./../../static/sleep.svg";
import { LoadingPage } from "./LoadingPage";

export const MainPage = () => {
  const [, setLocation] = useLocation();
  const { status, signOut, user } = useGoogleAuthSubscriber();

  if (status === "anonymous") {
    setLocation("/signin");
  }

  if (status === "guest") {
    setLocation("/nonregistered");
  }

  if (user === null || typeof user === "string") {
    return <LoadingPage />;
  }

  return (
    <div className="h-screen bg-gray-10 ">
      <div className="overflow-hidden h-full">
        <div className="h-full flex justify-between items-center flex-col">
          <NavBar close />
          <div className="flex flex-1 px-4 justify-center items-center flex-col w-full">
            <div className="flex flex-col space-y-2 mt-auto items-center justify-center mb-6">
              {user?.photoURL && (
                <img
                  alt="avatar"
                  src={user.photoURL}
                  className="w-20 h-20 rounded-full bg-gray-40 flex"
                />
              )}
              <h1 className="text-center text-base font-bold text-black">
                {user?.displayName}
              </h1>
            </div>
            <MenuItem
              onClick={() => setLocation("/sleep")}
              icon={<Sleep />}
              title="Sleep Settings"
            />
            <div className="my-2"></div>
            <MenuItem onClick={signOut} icon={<SignOut />} title="Log out" />
            <AppleButton />
          </div>
        </div>
      </div>
    </div>
  );
};
