import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";
import { initializeApp } from "firebase/app";

import { useEffect, useState } from "react";

type IframeUser = {
  displayName: string;
  photoURL: string;
};

type UserSupabaseData = {
  id: string;
  email: string;
  exportCalendarId: string | null;
  sleepStart: [number, number];
  sleepEnd: [number, number];
};

type UserType = "anonymous" | "guest" | "member";
const firebaseConfig = {
  apiKey: "AIzaSyDVWH3BI9Ii9kJww2-GfVI0j0H393g6esI",
  authDomain: "paced-ios.firebaseapp.com",
  projectId: "paced-ios",
  storageBucket: "paced-ios.appspot.com",
  messagingSenderId: "230174729604",
  appId: "1:230174729604:web:b277b66c8413fed516e9d1",
  measurementId: "G-LV4D2R5VK7",
};

initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();

const LOGIN_LINK =
  "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&client_id=230174729604-3sns2rglbk2dcgdjngv1c9t6fav0hgh2.apps.googleusercontent.com&prompt=consent&redirect_uri=https%3A%2F%2Fapi.paced.com%2Fauth%2Fhandler&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar";

const auth = getAuth();

export const useGoogleAuthSubscriber = () => {
  const [token, setToken] = useState("");
  const [status, setStatus] = useState<null | UserType>(null);
  const [user, setUser] = useState<null | IframeUser>(null);
  const [userData, setUserData] = useState<null | UserSupabaseData>(null);

  const signInWithRedirect = async () => {
    window.open(LOGIN_LINK, "_self");
  };

  const signIn = () => {
    const auth = getAuth();
    signInWithPopup(auth, provider);
  };
  const signOut = () => {
    setStatus("anonymous");
    const auth = getAuth();
    auth.signOut();
    window.parent.postMessage({ sender: "sidebar", type: "signout" }, "*");
  };

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, (user) => {
      if (auth.currentUser) {
        auth.currentUser.getIdToken().then((accessToken) => {
          const options = {
            method: "GET",
            headers: {
              "Access-Token": accessToken,
            },
          };
          const checkUser = () => {
            fetch("https://api.paced.com/v1/users/me", options)
              .then((r) => r.json())
              .then((r) => {
                if (!!r.data.user) {
                  clearInterval(interval);
                  setToken(accessToken);
                  setStatus("member");
                  setUser(auth.currentUser as IframeUser);
                  setUserData(r.data.user);
                } else {
                  setStatus("guest");
                }
              })
              .catch((e) => {
                setStatus("guest");
              });
          };
          checkUser();
          const interval = setInterval(checkUser, 5000);
        });
      } else {
        setStatus("anonymous");
        setToken("");
      }
    });
    return () => subscriber();
  }, []);
  return { token, status, user, userData, signIn, signOut, signInWithRedirect };
};

export const getAuthToken = async () => {
  if (!auth || !auth.currentUser) {
    return null;
  }
  const token = await auth.currentUser.getIdToken();
  return token;
};

export const checkUser = async (
  token: string,
  callback: (status: boolean) => void
) => {
  const options = {
    method: "GET",
    headers: {
      "Access-Token": token,
    },
  };

  const interval = setInterval(async () => {
    const result = await fetch("https://api.paced.com/v1/users/me", options)
      .then((r) => r.json())
      .then((r) => !!r.data.user)
      .catch((e) => false);

    if (result) {
      clearInterval(interval);
      callback(result);
    }
  }, 5000);
};
