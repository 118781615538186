import { Route, Router, Redirect } from "wouter";
import { WelcomePage } from "./pages/onboarding/WelcomPage";
import { NonRegisteredPage } from "./pages/onboarding/NonRegisteredPage";
import { LoadingPage } from "./pages/onboarding/LoadingPage";
import { OnboardingSlider } from "./pages/onboarding/OnboardingSlider";

const OnboardingRouter = (): JSX.Element => {
  return (
    <Router base="/onboarding">
      <Route path="/">
        <Redirect to="/welcome"></Redirect>
      </Route>
      <Route path="/welcome">
        <WelcomePage />
      </Route>
      <Route path="/nonregistered">
        <NonRegisteredPage />
      </Route>
      <Route path="/circadian">
        <OnboardingSlider />
      </Route>
      <Route path="/loading">
        <LoadingPage />
      </Route>
    </Router>
  );
};

export default OnboardingRouter;
