import { ReactElement } from "react";

type MenuItemProps = {
  title: string;
  icon: ReactElement;
  onClick?: () => void;
};

export const MenuItem = ({ onClick, icon, title }: MenuItemProps) => {
  return (
    <button
      onClick={onClick ? onClick : () => {}}
      className="h-11 flex px-4 space-x-3 items-center border border-black/5 w-full bg-white rounded-2xl hover:bg-gray-5"
    >
      {icon}
      <span className="text-button text-black font-semibold">{title}</span>
    </button>
  );
};
