export default function Button({
  title,
  leftIcon,
  rightIcon,
  loading,
  onClick,
  className,
}: {
  title: string;
  loading?: boolean;
  leftIcon?: any;
  rightIcon?: any;
  onClick: () => void;
  className?: string;
}) {
  return (
    <button
      disabled={loading}
      onClick={onClick}
      className={`relative px-10 py-4 bg-purple text-white rounded-full text-base font-semibold flex items-center justify-center ${
        className ? className : ""
      }`}
    >
      {leftIcon && !loading && <div className="mr-2">{leftIcon}</div>}
      {loading ? (
        <div className="border-2 border-white border-t-purple rounded-full animate-spin w-6 h-6"></div>
      ) : (
        title
      )}
      {rightIcon && !loading && <div className="ml-2">{rightIcon}</div>}
    </button>
  );
}
