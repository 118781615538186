import { useEffect } from "react";
import { Redirect, Route, Router } from "wouter";
import { AnonymousPage } from "./pages/iframe/AnonymousPage";
import { LoadingPage } from "./pages/iframe/LoadingPage";
import { MainPage } from "./pages/iframe/MainPage";
import { SignInPage } from "./pages/iframe/SignInPage";
import { SleepSettings } from "./pages/iframe/SleepSettings";
import { getAuthToken, useGoogleAuthSubscriber } from "./utils/auth";

const IframeRouter = (): JSX.Element => {
  const { token, status } = useGoogleAuthSubscriber();

  useEffect(() => {
    if (token) {
      window.parent.postMessage(
        { sender: "sidebar", type: "token", token: token },
        "*"
      );
    }
  }, [token]);

  useEffect(() => {
    const eventListener = async (event: MessageEvent) => {
      if (event.data.sender === "paced" && status === "member") {
        const accessToken = await getAuthToken();
        if (accessToken === null) {
          return;
        }
        window.parent.postMessage(
          { sender: "sidebar", type: "token", token: accessToken },
          "*"
        );
      }
    };
    window.addEventListener("message", eventListener);
    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, [status]);

  return (
    <Router base="/iframe">
      <Route path="/">
        <Redirect to="/loading"></Redirect>
      </Route>
      <Route path="/loading">
        <LoadingPage />
      </Route>
      <Route path="/signin">
        <SignInPage />
      </Route>

      <Route path="/nonregistered">
        <AnonymousPage />
      </Route>
      <Route path="/main">
        <MainPage />
      </Route>
      <Route path="/sleep">
        <SleepSettings />
      </Route>
    </Router>
  );
};

export default IframeRouter;
