import { useLocation } from "wouter";
import { NavBar } from "../../components";
import { useGoogleAuthSubscriber } from "../../utils/auth";
import calendar from "./../../static/calendar.svg";

export const LoadingPage = () => {
  const [, setLocation] = useLocation();
  const { status } = useGoogleAuthSubscriber();
  if (status === "member") {
    setLocation("/main");
  }
  if (status === "anonymous") {
    setLocation("/signin");
  }
  if (status === "guest") {
    setLocation("/nonregistered");
  }

  return (
    <div className="h-screen bg-gray-10 w-full">
      <div className="overflow-hidden h-full">
        <div className="h-full flex justify-between items-center flex-col">
          <NavBar close />
          <div className="flex flex-1 justify-center items-center flex-col px-4">
            <img
              src={calendar}
              alt="calendar icon"
              className="w-20 h-20 mb-4"
            />
            <h1 className="text-center text-lg font-bold text-black mb-3">
              Calculating your energy zones...
            </h1>
            <h2 className="text-center text-sm text-gray-80">
              Paced is analyzing your data and making custom energy predictions.
              <br />
              This will just take a moment.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};
