import { useLocation } from "wouter";
import { SmallAppleButton, NavBar } from "../../components";
import { useGoogleAuthSubscriber } from "../../utils/auth";
import mobileScreenshot from "./../../static/iframeMobile.svg";

export const AnonymousPage = () => {
  const [, setLocation] = useLocation();
  const { status } = useGoogleAuthSubscriber();
  if (status === "member") {
    setLocation("/main");
  }

  return (
    <div className="h-screen bg-gray-10 w-full">
      <div className="overflow-hidden h-full">
        <div className="h-full flex justify-between items-center flex-col">
          <NavBar close />
          <div className="flex flex-1 justify-center items-center flex-col px-4">
            <img
              src={mobileScreenshot}
              alt="mobile screenshot"
              className="w-full mb-4"
            />
            <h1 className="text-center text-lg font-bold text-black mb-3">
              Create your Paced account on the mobile app
            </h1>
            <h2 className="text-center text-sm text-gray-80 mb-4">
              This is necessary to connect Paced to Apple Health. Once you set
              up your account, come back to this page and click “I have
              an account”.
            </h2>
            <SmallAppleButton />
          </div>
        </div>
      </div>
    </div>
  );
};
