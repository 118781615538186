export default function TimeInput({
  label,
  value,
  className,
  onChange,
}: {
  label: string;
  value: string;
  className?: string;
  onChange: (value: string) => void;
}) {
  const onChangeValue = (value: string) => {
    onChange(value);
  };

  return (
    <div className="relative w-full">
      <div
        className={`${
          className ? className : ""
        } relative w-full flex items-center bg-white rounded-xl border border-black/5 px-4 py-3 space-x-1`}
      >
        <span className="text-button mr-auto">{label}</span>
        <input
          type="time"
          value={value}
          onChange={(e) => onChangeValue(e.target.value)}
          className="appearance-none outline-none text-base text-right"
        />
      </div>
    </div>
  );
}
