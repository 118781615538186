import logo from "./../static/logo.svg";
import onboardingLogo from "./../static/onboarding-logo.svg";
import closeIcon from "./../static/close.svg";
import { ReactComponent as ChevronLeft } from "../static/chevron-left.svg";

export const NavBar = ({
  close,
  onboarding,
  title,
  onBack,
}: {
  close?: boolean;
  onboarding?: boolean;
  title?: string;
  onBack?: () => void;
}) => {
  const closeModal = () => {
    window.parent.postMessage({ sender: "sidebar", type: "close" }, "*");
  };

  return (
    <nav
      style={{
        boxShadow: "0 1px 0 #DBDCE0",
      }}
      className={`${
        onboarding ? "h-18 px-12" : "h-16 px-4"
      } z-20 relative top-0 left-0 w-full bg-white flex justify-between items-center border-b border-black/5`}
    >
      {onBack !== undefined ? (
        <>
          <button className="mr-1" onClick={onBack}>
            <ChevronLeft />
          </button>
          <h3 className="text-base mr-auto">{title ?? "Back"}</h3>
        </>
      ) : onboarding ? (
        <img src={onboardingLogo} alt="Paced logo" className="h-10" />
      ) : (
        <>
          <img src={logo} alt="Paced logo" className="w-9 h-9 mr-2" />
          <span className="mr-auto text-lg font-bold text-purple">Paced</span>
        </>
      )}
      {close && (
        <button
          onClick={closeModal}
          className="w-8 h-8 flex items-center justify-center"
          title="Close Sidebar"
        >
          <img src={closeIcon} alt="close icon" className="w-5 h-5" />
        </button>
      )}
    </nav>
  );
};
