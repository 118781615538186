import OnboardingRouter from "./OnboardingRouter";
import IframeRouter from "./IframeRouter";

const App = (): JSX.Element => {
  return (
    <>
      <OnboardingRouter />
      <IframeRouter />
    </>
  );
};

export default App;
