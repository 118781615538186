import { useEffect, useState } from "react";
import { useLocation } from "wouter";
import { NavBar } from "../../components";
import TimeInput from "../../components/TimeInput";
import { useGoogleAuthSubscriber } from "../../utils/auth";
import { getAuth } from "firebase/auth";
import { padTime } from "../../utils/pad";

const auth = getAuth();

export const SleepSettings = () => {
  const [, setLocation] = useLocation();
  const { userData } = useGoogleAuthSubscriber();

  const [bedTime, setBedTime] = useState("22:00");
  const [wakeUpTime, setWakeUpTime] = useState("06:00");
  const [loading, setLoading] = useState(true);

  const onSave = async () => {
    if (!auth.currentUser) {
      throw new Error("Empty user");
    }
    const accessToken = await auth.currentUser!.getIdToken();
    if (!accessToken) {
      throw new Error("Empty access token");
    }

    setLoading(true);
    const body = JSON.stringify({
      sleepStart: bedTime.split(":").map(Number),
      sleepEnd: wakeUpTime.split(":").map(Number),
    });

    await fetch("https://api.paced.com/v1/users/sleep-schedule", {
      method: "POST",
      headers: {
        "Access-Token": accessToken,
      },
      body,
    });

    setLoading(false);
    window.parent.postMessage(
      { sender: "sidebar", type: "refresh_zones" },
      "*"
    );
    setLocation("/main");
  };

  useEffect(() => {
    if (userData) {
      const start = userData.sleepStart.map(padTime).join(":");
      const end = userData.sleepEnd.map(padTime).join(":");
      setBedTime(start);
      setWakeUpTime(end);
      setLoading(false);
    }
  }, [userData]);

  return (
    <div className="h-screen bg-gray-10 ">
      <div className="overflow-hidden h-full">
        <div className="h-full flex justify-between items-center flex-col">
          <NavBar
            close
            title="Sleep Settings"
            onBack={() => setLocation("/main")}
          />
          <div className="flex flex-1 px-4 justify-center items-center flex-col w-full">
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-center text-lg font-bold text-black mb-4">
                When do you usually sleep?
              </h1>
              <p className="text-gray-80 text-base mb-6 text-center">
                Enter your usual sleep schedule so we can predict your energy
                patterns. Don’t worry if this varies a bit.
              </p>
              <div className="w-full flex flex-col space-y-1 relative">
                <TimeInput
                  onChange={setBedTime}
                  label="Bed time"
                  value={bedTime}
                />
                <div className="w-3 flex-shrink-0"></div>
                <TimeInput
                  onChange={setWakeUpTime}
                  label="Wake up"
                  value={wakeUpTime}
                />
              </div>
              <button
                onClick={onSave}
                disabled={loading}
                className="mt-4 w-full h-11 px-6 py-3 bg-purple text-white rounded-full text-button font-semibold flex items-center justify-center"
              >
                {loading ? (
                  <div className="border-2 border-white border-t-purple rounded-full animate-spin w-3 h-3"></div>
                ) : (
                  "Save Settings"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
