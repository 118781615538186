import { ReactComponent as Apple } from "./../static/apple.svg";
import { ReactComponent as ArrowRight } from "./../static/arrowright.svg";
import arrowBanner from "./../static/arrow-banner.png";

export const AppleButton = () => {
  return (
    <div className="mt-auto relative">
      <p className="text-sm text-gray-80 mb-6 pr-8">
        To get support for multiple calendar accounts and automatic sleep
        tracking install Paced iOS app
      </p>
      <img
        src={arrowBanner}
        alt="arrow"
        className="absolute right-4 top-7 w-8 h-8 transform -scale-x-100 opacity-50"
      />
      <a
        target="_blank"
        rel="noreferrer"
        href="https://paced.onelink.me/OHpM/extension"
        className="flex p-4 items-center w-full bg-black rounded-2xl mb-5"
      >
        <Apple />
        <div className="mx-4 flex justify-center items-start flex-col">
          <span className="text-button font-bold text-white mb-2">
            Paced iOS app
          </span>
          <span className="text-sm text-white font-medium">Download now</span>
        </div>
        <ArrowRight className="ml-auto" />
      </a>
    </div>
  );
};
